<template>
  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
    <div class="works-block_effect">
      <div class="data-title py-4 text-center"><p>{{ data.company_name }}</p><p>{{ data.service_name }}</p></div>
      <!-- 画像部分 -->
      <div class="image-container">
        <img :src="data.img_url" :alt="data.img_alt" />
      </div>

      <!-- データ部分 -->
      <div class="data-container">
        <!-- タイトルとサブタイトル -->
        <p>{{ data.description }}</p>
        <div v-if="data.link" class="text-center">
          <v-chip
            color="rgba(217, 40, 40, 1)"
            color-text="#fff"
            dark
            :key="4"
            href="data.link"
            class="section-button btngrad"
            small
          >
            <span class="section-button_text">
              リンク先へ
            </span>
            <span><v-icon small>mdi-chevron-right</v-icon></span>
          </v-chip>
        </div>
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>

<style scoped>
.image-container {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.image-container img {
  width: 90%;
}

.part:hover .image-container img {
  transform: scale(1.1);
  transition: 0.3s;
}

.data-container {
  font-size: 14px;
  font-weight: 500;
  margin: 10px;
  padding-bottom: 10px;
}

.data-container h2 {
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 5px;
}

.name-list span {
  display: inline-block;
  margin-right: 5px;
}

.description {
  font-size: 16px;
}
.data-title{
  font-weight: 800;
  font-size: 26px;
  padding: 16px; /* 上下左右のパディングを均一に設定 */
  line-height: 1.2; /* ラインハイトを適切な値に設定 */
  min-height: 50px; /* 最小高さを設定（内容によって調整が必要かもしれません） */
}

@media (max-width: 600px) {
  .data-title {
    font-weight: 800;
    font-size:26px;
    min-height: auto; /* 最小高さを自動調整に */
    padding: 8px; /* 小さい画面でのパディングを小さくする */
  }
}

</style>
