import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from './plugins/vuetify'
import Meta from 'vue-meta';

Vue.config.productionTip = false;

// vue-metaを使用するためにVueに登録
Vue.use(Meta);

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
