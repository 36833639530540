
import Vue from "vue";
import Hero from "@/components/Hero.vue";

export default Vue.extend({
  data: () => ({
    navs: [
      ["ホーム", "home"],
      ["会社概要", "about"],
      ["サービス", "service"],
      ["採用", "recruit"],
      ["お問い合わせフォームへ", "contact"],
    ],
  }),
  metaInfo() {
    return {
      title: "株式会社村田ソフトウェアサービス",
      meta: [
        {
          name: "description",
          content:
            "株式会社村田ソフトウェアサービスは、受託開発とSES事業を手がけるソフトウェア開発会社です。Webシステムやスマートフォンアプリ開発を通じて、お客様の目標達成を最後までサポートします。お見積りやご相談はお気軽にお問い合わせください。",
        },
      ],
    };
  },
  mounted: function () {
    this.$nextTick(() => {
      this.adjustScale();
      window.addEventListener("resize", this.adjustScale);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustScale);
  },
  components: {
    Hero,
  },
  methods: {
    adjustScale: function () {
      const screenWidth = window.innerWidth;
      const scaleValue = screenWidth / 1920;
      const transformValue = `scale(${scaleValue})`;
      const target = document.getElementsByClassName("hero_main")[0] as HTMLDivElement;
      if (target) {
        target.style.transform = transformValue;
      } else {
        console.error("Target element not found");
      }
    },
  },
});
