
import Vue from "vue";
export default Vue.extend({
  name: "App",
  components: {},
  data: () => ({
    scrolled: 0,
    navs: [
      ["ホーム", "home"],
      ["会社概要", "about"],
      ["サービス", "service"],
      ["採用", "recruit"],
      ["お問い合わせ", "contact"],
    ],
    navsFooter: [
      ["会社概要", "about"],
      ["サービス", "service"],
      ["採用", "recruit"],
      ["お問い合わせ", "contact"],
      ["プライバシーポリシー", "privacy"],
    ],
    drawer: false,
    group: null,
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 600) {
        this.drawer = false;
      }
    },
    scroll_top() {
      window.scroll({ top: 0, behavior: "smooth" });
    },
    scroll_event() {
      if (window.scrollY > 100) {
        this.scrolled = 1;
      } else if (window.scrollY < 100) {
        this.scrolled = 0;
      }
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.scroll_event);
  },
});
