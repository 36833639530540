<template>
  <div class="service">
    <div class="bg-orange">
      <div class="section-container">
        <div class="content-header">
          <div class="size100_left">
            <div class="header-left-title">
              <div class="main-company_title__main">サービス</div>
              <h5 class="header-left_title__sub">Service</h5>
            </div>
            <div class="header-left_title__text">
              MSSでは、Webシステム・スマートフォンアプリ開発等を提供しています。<br />お客様のニーズに合わせた最適なソリューションをお約束します。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-visual">
      <div class="bg-orange main-visual_back1"></div>
      <div class="bg-white main-visual_back2"></div>
      <div class="main-visual-img_service-container">
        <div class="main-visual-img_service img-radius">
          <img src="../assets/service_main.jpg" width="100%"/>
        </div>
      </div>
    </div>
    <div class="bg-gray">
      <div class="section-container">
        <div class="section-title2">開発実績</div>
        <h5>Cases</h5>
      </div>

      <v-container>
        <v-row>
          <Part v-for="work in workList" :key="work.id" :data="work" />
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script lang="js">

import Part from "@/components/Part.vue";
import { getWorkList } from "../api";
export default {
  data() {
    return {
      workList: null, 
    };
  },
  mounted() {
    this.workList = this.getWorkList(); // コンポーネントがマウントされたらデータを取得
  },
  components: {
    Part,
  },
  methods: {
    async getWorkList() {
      try {
        const workList = await getWorkList();
        this.workList =  workList.data; // データをデータプロパティに設定
      } catch (error) {
        console.error("APIリクエストが失敗しました:", error);
      }
    },
  },
  metaInfo() {
    return {
      title: 'サービス | 株式会社村田ソフトウェアサービス',
      meta: [
        {
          name: 'description',
          content: '株式会社村田ソフトウェアサービスのサービス内容の紹介ページです。'
        },
      ],
    };
  },
};
</script>

<style>
.service-block_effect {
  background-color: #eaeef0;
  border-radius: 10px;
  box-shadow: 4px 4px 4px #aaa;
  margin: 10px;
}
.works-block_effect {
  background-color: white;
  border-radius: 10px;
  box-shadow: 4px 4px 4px #aaa;
  width: 100%;
}

.main-visual-img_service-container {
  position: relative;
  display: flex;
  justify-content: center; /* 水平方向の中央配置 */
  align-items: flex-start; /* 垂直方向の上端に配置 */
  max-width: 1920px; /* コンテナの最大幅 */
  width: 100%; /* コンテナの幅を画面幅に合わせる */
  margin: auto; /* 水平方向の中央に配置 */
  height: calc(100vw * 740 / 1920 - 300px); /* 元の高さから300px減らす */
}

.main-visual-img_service{
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  position: relative;
  width: calc(100vw * 1536 / 1920); /* 画面幅に基づいて幅を動的に計算 */
  height: calc(100vw * 740 / 1920 ); /* 画像の高さを幅に合わせて自動調整 */
  top:-300px;
}

.service-content {
  display: flex; /* Flexboxの有効化 */
  flex-wrap: wrap; /* コンテンツが多い場合に次の行へ折り返し */
  flex-direction: row; /* 子要素を行方向に配置 */
  justify-content: center; /* 中央寄せ */
  align-items: flex-start; /* 子要素を上端で揃える */
  margin: 0 auto; /* 水平方向の中央に配置 */
  width: 1000px;
}

.main-content_block {
  flex: 1 1 30%; /* 各ブロックの幅を柔軟に調整 */
  margin: 10px; /* ブロック間の余白 */
}

.service-content_block {
  padding: 20px;
  text-align: left;
}

@media (max-width: 600px) {
  .size100_left {
    text-align: left;
    margin: 4vw;
  }

  .header-right {
    display: none;
  }
  .main-company_title__main {
    margin: 0px;
    font-size: 8vw;
    font-weight: 800;
  }
  .header-left_title__sub {
    font-size: 4vw;
    margin-left: 4vw;
    margin-top: 2vw;
  }
  .service-block_effect {
    background-color: #eaeef0;
    border-radius: 10px;
    box-shadow: 4px 4px 4px #aaa;
    margin: 10px;
    width: 84vw;
    margin-left: auto;
    margin-right: auto;
  }
  li {
    text-align: left;
  }
  .service-block_text {
    text-align: left;
  }
  .main-visual-img_service-container {
    height: calc(70vw * 740 / 1920x); /* 元の高さから300px減らす */
  }
  .main-visual-img_service {
    position: relative;
    width: 95vw;
    top: -60vw;
    height: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  .service-content {
    flex-direction: column; /* スマホ表示では縦並びに */
    width: 100%;
  }
  .main-content_block {
    flex: 1 1 100%; /* スマホ表示では幅いっぱいに拡張 */
  }
}
</style>
