import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ServiceView from "../views/ServiceView.vue";
import WorksView from "../views/WorksView.vue";
import RecruitView from "../views/RecruitView.vue";
import ContactView from "../views/ContactView.vue";
import PrivacyView from "../views/PrivacyView.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  { // ホーム
    path: "/",
    name: "home",
    component: HomeView,
  },
  { //会社概要
    path: "/about",
    name: "about",
    component: AboutView,
  },
  { //サービス
    path: "/service",
    name: "service",
    component: ServiceView, 
  },
  { //採用
    path: "/recruit",
    name: "recruit",
    component: RecruitView,
  },
  { // お問い合わせ
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  { // プライバシーポリシー
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

export default router;
