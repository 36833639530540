
import Vue from "vue";
import { sendMailData } from "../api";
interface FormData {
  content: string;
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  phone: string;
  email: string;
  kind: string;
}

export default Vue.extend({
  data() {
    return {
      navs: [["ホーム", "home"]],
      formdata: {
        content: "",
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        phone: "",
        company: "",
        email: "",
        kind: "",
      } as FormData,
        fields: [
        {
          label: 'お問い合わせ区分',
          type: 'radio',
          options: { 1: '採用', 2: 'お仕事のご依頼', 3: 'お見積り・ご相談', 4: 'その他' },
          above_class: 'kind',
          class: 'kind',
          model: 'kind',
          required: true,
          validation: 'validation_kind',
          alert: 'alert_kind'
        },
        {
          label: 'お名前',
          type: 'text2',
          above_class: 'contact-main_name',
          classes: ['last_name', 'first_name'],
          models: ['last_name', 'first_name'],
          required: true,
          placeholders: ['  田中', '  太郎'],
          validation: 'validation_name',
          alert: 'alert_name'
        },
        {
          label: 'フリガナ',
          type: 'text2',
          above_class: 'contact-main_name',
          classes: ['last_name_kana', 'first_name_kana'],
          models: ['last_name_kana', 'first_name_kana'],
          required: true,
          placeholders: ['  タナカ', '  タロウ'],
          validation: 'validation_kanacheck',
          alert: 'alert_kana'
        },
        {
          label: '会社名',
          type: 'text',
          above_class: 'contact-main_mail',
          model: 'company',
          class: 'company',
          required: false,
          placeholder: '  ABC株式会社',
        },
        {
          label: 'メールアドレス',
          type: 'text',
          above_class: 'contact-main_mail',
          model: 'email',
          class: 'mail',
          required: true,
          placeholder: '  example.com',
          validation: 'validation_mail',
          alert: 'alert_mail'
        },
        {
          label: '電話番号',
          type: 'text',
          above_class: 'contact-main_mail',
          model: 'phone',
          class: 'phone',
          required: true,
          placeholder: '  1234-56-7890',
          validation: 'validation_phone',
          alert: 'alert_phone'
        },
        {
          label: 'お問い合わせ内容',
          type: 'textarea',
          above_class: 'contact-main_mail',
          model: 'content',
          class: 'contact_textarea',
          required: true,
          validation: 'validation_content',
          alert: 'alert_content'
        }
      ],
      alert_kind: "",
      alert_name: "",
      alert_kana: "",
      alert_mail: "",
      alert_phone: "",
      alert_content: "",
      alert_text: "",
      isSend: false,
    };
  },
  components: {},
  mounted: function () {
    this.isSend = false;
  },
  methods: {
    /**
     * バリデーション呼び出す
     * 
     * @function check_validation
     * @param {string} validationMethod - 種別
     * @returns {boolean} 入力内容が正しければ true、誤りがあれば false
    */
    callValidation(validationMethod: string) {
      if (typeof (this as any)[validationMethod] === 'function') {
        return (this as any)[validationMethod]();
      }
      return false;
    },
    /**
     * アラートメッセージを取得ｓするメソッド
     * 
     * @function getAlertMessage
     * @param {string} alertKey - アラート種別
     * @returns {string} アラートメッセージ
     */
    getAlertMessage(alertKey: string): string {
      switch(alertKey) {
        case 'alert_kind':
          return this.alert_kind;
        case 'alert_name':
          return this.alert_name;
        case 'alert_kana':
          return this.alert_kana;
        case 'alert_mail':
          return this.alert_mail;
        case 'alert_phone':
          return this.alert_phone;
        case 'alert_content':
          return this.alert_content;
        default:
          return "";
      }
    },
    /**
     * ひらがなをカタカナに変換するメソッド
     * 
     * @function convertToKatakana
     * @param {string} str - 変換するひらがな文字列
     * @returns {string} カタカナに変換された文字列
     */
    convertToKatakana(str: string): string {
      // ひらがなをカタカナに変換する処理
      return str.replace(/[ぁ-ん]/g, function(s: string) {
        return String.fromCharCode(s.charCodeAt(0) + 0x60);
      });
    },
    /**
     * お問い合わせ区分の入力内容を検証します。
     * 種目が選択されていない場合はエラーメッセージを設定します。
     * 
     * @function validation_kind
     * @returns {boolean} 入力内容が正しければ true、誤りがあれば false
     */
    validation_kind() {
      if (this.formdata.kind == "") {
        this.alert_kind = "お問い合わせ区分が入力されていません。";
        return false;
      }
      this.alert_kind = "";
      return true;
    },
    /**
     * 名前の入力内容を検証します。
     * 名字または名前が入力されていない場合はエラーメッセージを設定します。
     * 
     * @function validation_name
     * @returns {boolean} 入力内容が正しければ true、誤りがあれば false
     */
    validation_name() {
      if (this.formdata.last_name == "" || this.formdata.first_name == "") {
        this.alert_name = "名前が入力されていません。";
        return false;
      }
      this.alert_name = "";
      return true;
    },
    /**
     * フリガナの入力内容を検証し、ひらがなをカタカナに変換します。
     * 正しいカタカナが入力されているかをチェックし、不適切な場合はエラーメッセージを設定します。
     * 
     * @function validation_kanacheck
     * @returns {boolean} 入力内容が正しければ true、誤りがあれば false
     */
    validation_kanacheck() {
      // 入力されたひらがなをカタカナに変換する
      let last_name_kana = this.convertToKatakana(this.formdata.last_name_kana);
      let first_name_kana = this.convertToKatakana(this.formdata.first_name_kana);
      this.formdata.last_name_kana = last_name_kana;
      this.formdata.first_name_kana = first_name_kana;

      if (this.formdata.last_name_kana == "" || this.formdata.first_name_kana == "") {
        // フリガナが未入力の場合
        this.alert_kana = "フリガナが入力されていません。";
        return false;
      }

      const isKana = /^[ァ-ヶーぁ-ゖ]+$/;
      let checkLast = last_name_kana.match(isKana);
      let checkFirst = first_name_kana.match(isKana);

      if (checkLast == null || checkFirst == null) {
        // フリガナが入力されているが、カタカナでない場合
        this.alert_kana = "カナで入力されていません。";
        return false;
      }

      this.alert_kana = "";
      return true;
    },
    /**
     * メールアドレスの入力内容を検証し、正しい形式であるかをチェックします。
     * 不適切な場合はエラーメッセージを設定します。
     * 
     * @function validation_mail
     * @returns {boolean} 入力内容が正しければ true、誤りがあれば false
     */
    validation_mail() {
      let checkMail = this.formdata.email.match(/.+@.+\..+/);
      if (checkMail != null) {
        this.alert_mail = "";
        return true;
      } else if (this.formdata.email == "") {
        this.alert_mail = "メールアドレスが入力されていません。";
        return false;
      } else {
        this.alert_mail = "メールアドレスの形式が正しくありません。";
        return false;
      }
    },
    /**
     * 電話番号の入力内容を検証し、正しい形式であるかをチェックします。
     * 不適切な場合はエラーメッセージを設定します。
     * 
     * @function validation_phone
     * @returns {boolean} 入力内容が正しければ true、誤りがあれば false
     */
    validation_phone() {
      let checkPhone = this.formdata.phone.match(/^[0-9-]+$/);
      if (checkPhone != null) {
        this.alert_phone = "";
        return true;
      } else if (this.formdata.phone == "") {
        this.alert_phone = "電話番号が入力されていません。";
        return false;
      } else {
        this.alert_phone = "電話番号の形式が正しくありません。";
        return false;
      }
    },
    /**
     * お問い合わせ内容の入力内容を検証します。
     * 内容が入力されていない場合はエラーメッセージを設定します。
     * 
     * @function validation_content
     * @returns {boolean} 入力内容が正しければ true、誤りがあれば false
     */
    validation_content() {
      if (this.formdata.content == "") {
        this.alert_content = "お問い合わせ内容が入力されていません。";
        return false;
      }
      this.alert_content = "";
      return true;
    },
    /**
     * メール送信フォームの入力内容を検証し、全てのフィールドが正しく入力されているかをチェックします。
     * エラーメッセージをまとめて表示します。
     * 
     * @async
     * @function sendMail
     * @returns {Promise<void>} フォーム送信の結果（エラーメッセージがあれば表示し、成功時には送信完了を通知します）
     */
    async sendMail() {
      this.alert_text = "";
      let errorMessages = [];

      if (!this.validation_kind()) {
        errorMessages.push(this.alert_kind);
      }
      if (!this.validation_name()) {
        errorMessages.push(this.alert_name);
      }
      if (!this.validation_kanacheck()) {
        errorMessages.push(this.alert_kana);
      }
      if (!this.validation_mail()) {
        errorMessages.push(this.alert_mail);
      }
      if (!this.validation_phone()) {
        errorMessages.push(this.alert_phone);
      }
      if (!this.validation_content()) {
        errorMessages.push(this.alert_content);
      }

      if (errorMessages.length > 0) {
        alert(errorMessages.join("\n"));
      } else {
        // チェックOK
        let json = JSON.stringify(this.formdata);
        const res = await sendMailData(json);
        this.isSend = res;
        if (res == false) {
          this.alert_text = "送信エラーが発生しました。";
          alert(this.alert_text);
        }
      }
    },
  },
  metaInfo() {
    return {
      title: 'お問い合わせ | 株式会社村田ソフトウェアサービス',
      meta: [
        {
          name: 'description',
          content: '株式会社村田ソフトウェアサービスのお問い合わせページです。'
        },
      ],
    };
  },
});
