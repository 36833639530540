<template>
  <div class="home-head-container section-flex">
    <div class="head-container-section">
      <div class="hero_section">
        <div class="hero_main">
          <div class="hero">
            <div class="background">
              <img
                src="../assets/top/01.svg"
                alt="左上白灰色"
                class="top_left_1"
              />
              <img
                src="../assets/top/02.svg"
                alt="左上黒灰色"
                class="top_left_2"
              />
              <img
                src="../assets/top/04.svg"
                alt="右上白灰色"
                class="top_right_1"
              />
              <img
                src="../assets/top/03.svg"
                alt="右上黒灰色1"
                class="top_right_2"
              />
              <img
                src="../assets/top/03.svg"
                alt="右上黒灰色2"
                class="top_right_3"
              />
            </div>

            <div class="center_image">
              <img
                src="../assets/top/img_pair_programming.png"
                alt="中央ヒーロー画像"
                class="hero_center_image"
              />
              <img
                src="../assets/top/09.svg"
                alt="中央画像フレーム1"
                class="center_image_frame_1"
                style="transform: rotate(180deg)"
              />
              <img
                src="../assets/top/05.svg"
                alt="中央画像フレーム2"
                class="center_image_frame_2"
              />
              <img
                src="../assets/top/06.svg"
                alt="中央画像フレーム3"
                class="center_image_frame_3"
              />
            </div>
            <div class="left_image">
              <img
                id="img_company_trip"
                src="../assets/top/img_company_trip.png"
                alt="左ヒーロー画像"
                class="hero_left_image"
              />
              <img
                src="../assets/top/09.svg"
                alt="左画像フレーム1"
                class="left_image_frame_1"
              />
              <img
                src="../assets/top/07.svg"
                alt="左画像フレーム2"
                class="left_image_frame_2"
              />
              <img
                src="../assets/top/08.svg"
                alt="左画像フレーム3"
                class="left_image_frame_3"
              />
            </div>
            <div class="right_image">
              <img
                id="img_interview"
                src="../assets/top/img_interview.png"
                alt="右ヒーロー画像"
                class="hero_right_image"
              />
              <img
                src="../assets/top/09.svg"
                alt="右画像フレーム1"
                class="right_image_frame_1"
              />
              <img
                src="../assets/top/08.svg"
                alt="右画像フレーム2"
                class="right_image_frame_2"
              />
              <img
                src="../assets/top/07.svg"
                alt="右画像フレーム3"
                class="right_image_frame_3"
              />
            </div>
            <div class="top_mission"></div>
          </div>
        </div>
      </div>
      <div class="center_arrow">
        <img src="../assets/top/scroll_sp.png" alt="arrow" />
      </div>
      <h1 class="home-head-container-text">
        技術の力で<br />関わるすべての人を<br />笑顔に
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .home-head-container {
    width: 600px;
    overflow: hidden;
  }
  .home-head-container-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 10vw;
  }
  .home-head-container {
    width: 100%;
    height: auto;
  }
  .head-container-section {
    width: 100%;
    height: 140vw;
    position: relative;
  }

  .hero_section {
    position: relative;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0) scale(2.5);
  }
  .hero_main {
    position: absolute;
    display: flex;
  }
  .hero {
    position: absolute;
    width: 1920px;
    height: 1093px;
  }

  /* top hero バックグラウンド */
  .hero .background {
    position: absolute;
    left: -398px;
  }
  .hero .background .top_left_1 {
    display: none;
    position: absolute;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .background .top_left_2 {
    display: none;
    position: absolute;
    top: 339px;
    left: 196px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .background .top_right_1 {
    display: none;
    position: absolute;
    left: 1920px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .background .top_right_2 {
    display: none;
  }
  .hero .background .top_right_3 {
    display: none;
  }

  /* top hero 中央画像 */
  .hero .center_image {
    position: absolute;
    left: 503px;

    width: 920px;
    height: 800px;
    overflow: hidden;
  }
  .hero .center_image .hero_center_image {
    position: absolute;
    top: -4px;
    left: -1px;
  }
  .hero .center_image .center_image_frame_1 {
    position: absolute;
    left: 0px;
    height: 792.727px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .center_image .center_image_frame_2 {
    position: absolute;
    left: 0px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .center_image .center_image_frame_3 {
    position: absolute;
    left: 225px;
    top: 391px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  /* top hero 左画像 */
  .hero .left_image {
    position: absolute;
    left: 140px; /* 右画像も同じ数値だけずらす */
    top: 402px;
  }
  .hero .left_image .left_image_frame_1 {
    position: absolute;
    left: 0;
    height: 691;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .left_image .left_image_frame_2 {
    position: absolute;
    left: 196px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .left_image .left_image_frame_3 {
    position: absolute;
    left: 405px;
    top: 351px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }

  /* top hero 右画像 */
  .hero .right_image {
    position: absolute;
    left: 980px;
    top: 402px;
  }
  .hero .right_image .right_image_frame_1 {
    position: absolute;
    left: 0;
    height: 691;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .right_image .right_image_frame_2 {
    position: absolute;
    left: 203px;
    top: 0px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .hero .right_image .right_image_frame_3 {
    position: absolute;
    left: 391px;
    top: 340px;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
  .center_arrow {
    top: 85%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);
  }
}
@media (min-width: 600px) and (max-width: 1920px) {
  .home-head-container-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 5vw;
  }
  .home-head-container {
    width: 100%;
    height: auto;
  }
  .head-container-section {
    width: 100%;
    height: auto;
    position: relative;
  }

  .hero_section {
    position: relative;
    width: 100%;
    height: calc((100vw / 1920) * 1093);
  }
  .hero_main {
    position: absolute;
    display: flex;
    transform: scale(0.5);
    top: -100px;
  }
  .hero {
    position: absolute;
    width: 1920px;
    height: 1093px;
  }

  /* top hero 中央画像 */
  .hero .center_image {
    position: absolute;
    left: 503px;
    top: 205px;
    width: 920px;
    height: 800px;
    overflow: hidden;
  }

  /* top hero 左画像 */
  .hero .left_image {
    position: absolute;
    left: 0px;
    top: 402px;
  }

  /* top hero 右画像 */
  .hero .right_image {
    position: absolute;
    left: 1120px;
    top: 402px;
  }

  .center_arrow {
    display: none;
  }
}

@media (min-width: 1920px) {
  .home-head-container-text {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 5vw;
  }
  .home-head-container {
    width: 100%;
    height: auto;
  }
  .head-container-section {
    width: 100%;
    height: auto;
    position: relative;
  }

  .hero_section {
    position: relative;
    width: 100%;
    height: calc((100vw / 1920) * 1093);
  }
  .hero_main {
    position: absolute;
    display: flex;
    transform: scale(0.5);
    top: -100px;
  }
  .hero {
    position: absolute;
    width: 1920px;
    height: 1093px;
  }

  /* top hero 中央画像 */
  .hero .center_image {
    position: absolute;
    left: 503px;
    top: 205px;
    width: 920px;
    height: 800px;
    overflow: hidden;
  }

  /* top hero 左画像 */
  .hero .left_image {
    position: absolute;
    left: 0px;
    top: 402px;
  }

  /* top hero 右画像 */
  .hero .right_image {
    position: absolute;
    left: 1120px;
    top: 402px;
  }

  .center_arrow {
    display: none;
  }
}
/* top hero バックグラウンド */
.hero .background {
  position: absolute;
  left: -398px;
}
.hero .background .top_left_1 {
  position: absolute;

}
.hero .background .top_left_2 {
  position: absolute;
  top: 339px;
  left: 196px;

}
.hero .background .top_right_1 {
  position: absolute;
  left: 1920px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .background .top_right_2 {
  position: absolute;
  left: 2116px;
  top: 339px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .background .top_right_3 {
  position: relative;
  left: 1920px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .center_image .hero_center_image {
  position: absolute;
  top: -4px;
  left: -1px;
}
.hero .center_image .center_image_frame_1 {
  position: absolute;
  left: 0px;
  height: 792.727px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .center_image .center_image_frame_2 {
  position: absolute;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .center_image .center_image_frame_3 {
  position: absolute;
  left: 225px;
  top: 391px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .left_image .left_image_frame_1 {
  position: absolute;
  left: 0;
  height: 691;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .left_image .left_image_frame_2 {
  position: absolute;
  left: 196px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .left_image .left_image_frame_3 {
  position: absolute;
  left: 405px;
  top: 351px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .right_image .right_image_frame_1 {
  position: absolute;
  left: 0;
  height: 691;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .right_image .right_image_frame_2 {
  position: absolute;
  left: 203px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
.hero .right_image .right_image_frame_3 {
  position: absolute;
  left: 391px;
  top: 340px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
</style>
