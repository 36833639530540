<template>
  <div>
    <div class="main-access-cont">
      <div class="main-access_title">{{ title }}</div>
      <div class="main-access_text">〒{{ postID }}</div>
      <div class="main-access_text">
        {{ adress }}
      </div>
    </div>
    <div class="main-access_map">
      <iframe
        :src="mapURL"
        width="660"
        height="450"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        class="forspmap"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    postID: String,
    adress: String,
    mapURL: String,
  },
};
</script>

<style scoped>
.main-access-cont {
  width: 100%;
  font-size: 10px;
  text-align: left;
}
.main-access_title {
  font-size: 18px;
  font-weight: 800;
  margin-top: 10px;
}
.main-access_text {
  margin-top: 6px;
  font-size: 14px;
}
.main-access_map {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 600px) {
  .main-access_title {
    font-size: 6vw;
    margin-left: 4vw;
  }
  .main-access_text {
    font-size: 4vw;
    margin-left: 4vw;
  }
  .forspmap {
    width: 90vw;
  }
}
</style>
