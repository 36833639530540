
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      members: [
        {
          title: "飲食店からエンジニアを目指している女性",
          text: "我々のチームは、多様なバックグラウンドを持つ人々が集まっています。例えば、飲食業界からエンジニアを目指して転職を決意した女性メンバーは、自身の経験を生かしつつ新たなスキルを磨いています。",
        },
        {
          title: "未経験から５年で拠点立ち上げに挑戦しているメンバー",
          text: "私たちのチームには、エンジニア未経験から始め、５年で新拠点立ち上げに抜擢！全国拠点売り上げ一位の野望に向けて全力疾走するメンバーがいます！",
        },
        {
          title: "休日は趣味でカメラで写真を撮っているメンバー",
          text: "我々の仕事は、エンジニアリングだけでなく、それぞれのライフスタイルも大切にしています。たとえば、休日には趣味でカメラを持って街に繰り出すメンバーもいます。彼の視点から捉えられた世界は、チームに新鮮な視点をもたらしています。",
        },
        {
          title: "東京支社立ち上げにチャレンジしているメンバー",
          text: "東京支社の設立をリードしているメンバーは、チャレンジ精神を持って新たな道を切り開いています。彼らの仕事は、会社の成長だけでなく、自身のキャリアの発展にも繋がっています。",
        },
        {
          title: "ひげをはやしているメンバー",
          text: "私たちのチームには、個性的なスタイルを楽しむメンバーもいます。たとえば、ひげを伸ばしているメンバーは、そのユニークな外見と共に、自由な発想を活かし、創造力を発揮しています。",
        },
      ],
      snsLinks: [
        { name: "X", url: "https://x.com/mss_anonym", icon: "x-icon.png" },
        { name: "Instagram", url: "https://www.instagram.com/mss_inc.official", icon: "instagram-icon.png" },
        { name: "Facebook", url: "https://www.facebook.com/mss.software", icon: "facebook.png" },
      ],
      isButtonDisabled: false,
      showStickyContainer: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const howToApply = document.getElementById("HowtoApply");
      const stickyContainer = document.getElementById("sticky-container");
      if (!howToApply || !stickyContainer) return;

      const howToApplyRect = howToApply.getBoundingClientRect();
      const stickyRect = stickyContainer.getBoundingClientRect();

      if (howToApplyRect.top < stickyRect.bottom) {
        this.showStickyContainer = false; // 非表示にする
        this.isButtonDisabled = true; // ボタンを無効化
      } else {
        this.showStickyContainer = true; // 表示する
        this.isButtonDisabled = false; // ボタンを有効化
      }
    },
  },
  components: {},
  metaInfo() {
    return {
      title: "採用情報 | 株式会社村田ソフトウェアサービス",
      meta: [
        {
          name: "description",
          content: "株式会社村田ソフトウェアサービスの採用ページです。",
        },
      ],
    };
  },
});
