import Vue from "vue";
import axios from "axios";

Vue.config.productionTip = false;

// GoのAPIエンドポイント
const apiEndpoint = process.env.VUE_APP_API_URL;

// Vue.jsからGo APIにアクセスする
export async function getWorkList() {
  try {
    const response = await axios.get(`${apiEndpoint}/works`);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("取得中にエラーが発生しました:", error);
    throw error;
  }
}
//メールを送信する
export function sendMailData(jsondata: string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${apiEndpoint}/contact`);
      xhr.setRequestHeader(
        "content-type",
        "application/x-www-form-urlencoded;charset=UTF-8"
      );
      xhr.onload = function () {
        if (xhr.status === 200) {
          // 成功時の処理
          resolve(true);
        } else {
          // エラー時の処理
          resolve(false);
        }
      };
      xhr.onerror = function () {
        // エラー時の処理
        reject(new Error("送信中にエラーが発生しました"));
      };
      xhr.send(jsondata);
    } catch (error) {
      console.error("送信中にエラーが発生しました:", error);
      reject(error);
    }
  });
}